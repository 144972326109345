import { Injectable } from '@angular/core';
import { User } from './user.model';

@Injectable({
    providedIn: 'root',
})
export class UserStorageService {
    saveUser(user: User): void {
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUser(): User | null {
        const user = localStorage.getItem('user');
        return user ? (JSON.parse(user) as User) : null;
    }

    removeUser(): void {
        localStorage.removeItem('user');
    }
}
