import { fromZonedTime } from 'date-fns-tz';
import { differenceInMinutes, intervalToDuration, isValid, parse, parseISO } from 'date-fns';

const DATE_FORMATS = ['yyyy-MM-dd HH:mm:ss', 'yyyy-MM-dd', "yyyy-MM-dd'T'HH:mm:ss", "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"];

export const ZURICH_TIMEZONE = 'Europe/Zurich';
export const UNSPECIFIED_DELAY_VALUE = 'unspecifiedDelay';

export function convertToIsoUtc(dateString: string | null): string {
    if (!dateString) return '';

    if (dateString.includes(UNSPECIFIED_DELAY_VALUE)) return UNSPECIFIED_DELAY_VALUE;

    const parsedDate = parseDate(dateString);
    if (!isValid(parsedDate)) {
        throw new Error(`Invalid date format: ${dateString}`);
    }

    return toIsoString(parsedDate);
}

function toIsoString(dateString: string | Date): string {
    const utcDate = fromZonedTime(dateString, ZURICH_TIMEZONE);
    return utcDate.toISOString();
}

function parseDate(dateString: string): Date {
    for (const format of DATE_FORMATS) {
        const date = parse(dateString, format, new Date());
        if (isValid(date)) return date;
    }
    return new Date(dateString); // Fallback to native parsing
}

/**
 * @param durationDelta Format: HH:mm:ss
 */
export function getDelayInMinutesFromDurationDelta(durationDelta?: string): number | null {
    if (!durationDelta) return null;

    try {
        // Ajouter une date fictive pour créer un objet Date valide
        const durationDate = parseISO(`2000-01-01T${durationDelta}`);
        const baseDate = parseISO('2000-01-01T00:00');

        return differenceInMinutes(durationDate, baseDate);
    } catch (error) {
        console.error('Erreur lors du parsing de la durée:', error);
        return null;
    }
}

/**
 *
 * @param durationInMS Duration exprimed in milliseconds. Can be negative.
 * @returns a duration as a "+/-HH:mm:ss" string. milliseconds are omitted.
 */
export function formatMsToDuration(durationInMS: number): string {
    if (durationInMS === 0) return '';

    const isNegative = durationInMS < 0;
    const duration = intervalToDuration({ start: 0, end: Math.abs(durationInMS) });
    const hours = duration.hours ? duration.hours.toString().padStart(2, '0') : '00';
    const minutes = duration.minutes ? duration.minutes.toString().padStart(2, '0') : '00';
    const seconds = duration.seconds ? duration.seconds.toString().padStart(2, '0') : '00';

    let result = `${hours}:${minutes}:${seconds}`;

    result = (isNegative ? '-' : '+') + result;

    return result;
}

/**
 *
 * @param durationAsString Duration as a synthese string (HH:mm:ss)
 * @returns the duration in milliseconds
 */
export const parseSyntheseDurationInMS = (durationAsString: string): number => {
    // Format HH:mm:ss
    const SYNTHESE_DURATION_REGEX = /^\d{2}:\d{2}:\d{2}$/;

    if (!SYNTHESE_DURATION_REGEX.test(durationAsString)) {
        throw new Error('Invalid duration format. Please use HH:mm:ss');
    }
    const [hours, min, sec] = durationAsString.split(':').map((x) => parseInt(x));
    if (min !== min % 60 || sec !== sec % 60) {
        throw new Error('Invalid duration value. mm and ss must be smaller than 60.');
    }

    return (hours * 3_600 + min * 60 + sec) * 1_000;
};
